<template>
  <el-popover
      placement="right"
      width="260"
      trigger="click"
      v-model="visible"
      @show="showMsg">
    <el-form class="price_popover_form" ref="form" :model="form" label-width="100px">
      <el-form-item label="运费价格">
        <el-input-number style="width: 100%" v-model="form.jkPrice" size="mini" :min="0" :controls="false" :disabled="disabled"></el-input-number>
      </el-form-item>
      <el-form-item label="送货费价格">
        <el-input-number style="width: 100%" v-model="form.jkShf" size="mini" :min="0" :controls="false" :disabled="disabled"></el-input-number>
      </el-form-item>
      <el-form-item label="装卸费价格">
        <el-input-number style="width: 100%" v-model="form.jkZxf" size="mini" :min="0" :controls="false" :disabled="disabled"></el-input-number>
      </el-form-item>
      <el-form-item label="服务费价格">
        <el-input-number style="width: 100%" v-model="form.jkFwf" size="mini" :min="0" :controls="false" :disabled="disabled"></el-input-number>
      </el-form-item>
      <el-form-item label="其他费价格">
        <el-input-number style="width: 100%" v-model="form.jkQtf" size="mini" :min="0" :controls="false" :disabled="disabled"></el-input-number>
      </el-form-item>
      <el-form-item v-if="!disabled">
        <el-button style="align-items: center" type="primary" size="mini" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
    <div slot="reference" style="display: flex;justify-content: center;align-items: center;height: 33px">
      <el-button type="primary">点击</el-button>
    </div>
  </el-popover>
</template>
<script>
import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-price-popover',
  data () {
    return {
      form: {},
      options: [],
      loading: false,
      visible: false,
      mdmStrMark: ''
    }
  },
  props: {
    jkPriceStr: {
      type: String,
      default: () => ''
    },
    jkShfStr: {
      type: String,
      default: () => ''
    },
    jkZxfStr: {
      type: String,
      default: () => ''
    },
    jkFwfStr: {
      type: String,
      default: () => ''
    },
    jkQtfStr: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    showMsg () {
      this.getStartValues()
    },
    onSubmit (val) {
      this.visible = false
      this.$emit('change', this.form)
    },
    getStartValues () {
      if (!validatenull(this.jkPriceStr)) {
        let jkPrice = this.$parent.elForm.model[this.jkPriceStr]
        console.log('jkPrice：', jkPrice)
        if (!validatenull(jkPrice)) {
          this.$set(this.form, 'jkPrice', jkPrice)
        } else {
          this.$set(this.form, 'jkPrice', 0)
        }
      }
      if (!validatenull(this.jkShfStr)) {
        let jkShf = this.$parent.elForm.model[this.jkShfStr]
        console.log('jkShf：', jkShf)
        if (!validatenull(jkShf)) {
          this.$set(this.form, 'jkShf', jkShf)
        } else {
          this.$set(this.form, 'jkShf', 0)
        }
      }
      if (!validatenull(this.jkZxfStr)) {
        let jkZxf = this.$parent.elForm.model[this.jkZxfStr]
        console.log('jkZxf：', jkZxf)
        if (!validatenull(jkZxf)) {
          this.$set(this.form, 'jkZxf', jkZxf)
        } else {
          this.$set(this.form, 'jkZxf', 0)
        }
      }
      if (!validatenull(this.jkFwfStr)) {
        let jkFwf = this.$parent.elForm.model[this.jkFwfStr]
        console.log('jkFwf：', jkFwf)
        if (!validatenull(jkFwf)) {
          this.$set(this.form, 'jkFwf', jkFwf)
        } else {
          this.$set(this.form, 'jkFwf', 0)
        }
      }
      if (!validatenull(this.jkQtfStr)) {
        let jkQtf = this.$parent.elForm.model[this.jkQtfStr]
        console.log('jkQtf：', jkQtf)
        if (!validatenull(jkQtf)) {
          this.$set(this.form, 'jkQtf', jkQtf)
        } else {
          this.$set(this.form, 'jkQtf', 0)
        }
      }
    }
  },
  watch: {
  },
  mounted () {
    this.getStartValues()
  }
}
</script>

<style lang="scss">
.price_popover_form {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-price-popover/index.vue"
}
</vue-filename-injector>
